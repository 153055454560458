/* You can add global styles to this file, and also import other style files */

@font-face {
	font-family: "Poppins";
	src: url("assets/poppins-medium-webfont.woff2");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("assets/poppins-bold-webfont.woff2");
	font-weight: 600;
	font-style: normal;
}

body {
	margin: 0px;
	font-size: 1em;
	font-family: "Helvetica Neue";
	color: @black;
	min-width: 1200px;
}

@black: #00040C;
@light-grey: #E6E6E6;
@dark-blue: #126AEA;
@light-blue: #5A9EFF;
@dark-yellow: #FEC371;

.button-default() {
	float: right;
	background: @dark-yellow;
	border-radius: 4px;
	border: 0px;
	height: 46px;
	color: #232323;
	font-family: "Helvetica Neue";
	font-size: 16px;
	font-weight: 500;
	width: 100%;
	cursor: pointer;
	margin: 20px 0;
}

.arrow-right {
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid @black;
	display: block;
	cursor: pointer;
	&.rotated {
		transform: rotate(90deg)
	}
}

table.claims tr td {
	border-bottom: 0px;
}

.configurations {
	table {
		width: 600px;
		thead tr {
			background: #E6E6E6;
		}
	}
}

.button-default() {
	float: right;
	background: #FEC371;
	border-radius: 4px;
	border: 0px;
	height: 46px;
	color: #232323;
	font-family: "Helvetica Neue";
	font-size: 16px;
	font-weight: 500;
	width: 100%;
	cursor: pointer;
	margin: 20px 0;
}

h1, h2, h3, h4 {
	font-family: "Poppins";
}

p {
	line-height: 1.8em;
}

form.padded {
	background: white;
	border: 1px solid @light-grey;
	border-radius: 8px;
	padding: 24px;
	overflow: hidden;
}

label {
	line-height: 20px;
	margin-bottom: 6px;
	display: block;
	font-weight: 500;
	font-size: 14px;
	position: relative;
	&.inline {
		display: inline-block;
	}
	input[type="checkbox"] {
		height: auto;
		margin: 0 10px 0 0;
	}
	span.info {
		top: 0px;
		right: -32px;
		p {
			bottom: 18px;
		}
	}
}

img.check {
	margin: 9px 0;
	float: right;
}

input, select, .multiple-select {
	display: block;
	padding: 8px 12px;
	border-radius: 4px;
	border: 1px solid @light-grey;
	margin-right: 16px;
	font-size: 16px;
	height: 38px;
	font-family: "Helvetica Neue";
	color: #232323;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 0px;
	}
	&[type="checkbox"] {
		display: inline-block;
		width: auto;
	}
	&.ng-invalid {
		box-shadow: 0px 0px 0px 4px rgba(255, 0, 0, 1);
	}
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 7px;
	text-indent: 0px;
	&[multiple] {
		background: none;
		height: 150px;
	}
}

.multiple-select {
	list-style: none;
	height: 150px;
	overflow: scroll;
}

.inline {
	display: flex;
	a.button {
		margin-bottom: 24px;
	}
}

form {
	text-align: left;
	&.inline {
		input, select {
			display: inline-block;
			margin-bottom: 0px;
			width: auto;
		}
		button {
			width: auto;
		}
	}
	button {
		.button-default();
	}
}

.settings {
	background: white;
	border: 1px solid @light-grey;
	width: 100%;
	border-spacing: 0px;
	border-radius: 8px;
	.settings-section {
		padding: 24px;
		border-bottom: 1px solid @light-grey;
		margin-bottom: 0px;
		&:last-child {
			border: 0px;
		}
		div {
			flex-grow: 1;
		    flex-basis: 0;
		    h4 {
		    	font-size: 14px;
		    	color: #666666;
		    	font-weight: 500;
		    	margin: 0px;
		    }
		    label {
		    	position: relative;
		    	&.inline {
		    		display: inline-block;
		    	}
		    }
		    .field {
		    	height: 40px;
		    	line-height: 40px;
		    	font-size: 16px;
		    	font-weight: 400;
		    }
		}
	}
}

section.events {
	div.event {
		margin-bottom: 30px;
		position: relative;
		&:last-child {
			margin-bottom: 0px;
		}
		div.dot {
			position: absolute;
			div {
				background: #E6E6E6;
				height: 10px;
				width: 10px;
				border-radius: 100%;
				margin: 5px 0;
			}
		}
		div.line {
			width: 0px;
			position: absolute;
			left: 4px;
			top: 15px;
			bottom: -35px;
			border: 1px solid #E6E6E6;
		}
		&:last-child {
			div.line {
				display: none;
			}
		}
		div.title {
			display: flex;
			margin-left: 20px;
			line-height: 20px;
			font-size: 14px;
			a {
				text-decoration: none;
				font-weight: bold;
				color: #00040C;
			}
			div.date {
				margin-left: auto;
				font-size: 12px;
				color: #777;
			}
		}
		div.metadata {
			margin-top: 20px;
			height: 18px;
			margin-left: 20px;
			padding: 0px;
			border: 0px;
			display: flex;
			font-size: 14px;
			overflow: visible;
			margin-bottom: 0px;
			div {
				margin-right: 10px;
				line-height: 18px;
				&:last-child {
					margin: 0px;
				}
			}
			div.action {
				margin-left: auto;
				.button {
					padding: 4px 14px;
				}
			}
		}
		pre {
			background: #E6E6E6;
			border-radius: 8px;
			margin-left: 20px;
			padding: 16px;
			margin-top: 20px;
			max-height: 400px;
			margin-bottom: 0px;
		}
	}
}

table {
	background: white;
	border: 1px solid @light-grey;
	width: 100%;
	border-spacing: 0px;
	border-radius: 8px;
	font-size: 0.9em;
	overflow: hidden;
	margin-bottom: 24px;
	&.disabled {
		opacity: 0.5;
	}
	&.overflow {
		td {
			overflow: visible;
			white-space: pre-wrap;
		}
	}
	&.rows {
		margin-bottom: 77px;
		tbody tr td {
			max-width: 170px;
		}
	}
	thead {
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid @light-grey;
		color: #858892;
		td {
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			padding: 32px 12px 8px 12px;
			&:first-child {
				padding-left: 24px;
			}
			&:last-child {
				padding-right: 24px;
			}
			img {
				height: 10px;
			}
			a {
				color: #858892;
				position: relative;
				&:hover {
					text-decoration: none;
				}
				.arrow {
					position: absolute;
					top: 6px;
					right: -14px;
				}
			}
		}
	}
	tbody {
		&.open {
			tr.hidden {
				display: table-row;
			}
		}
	}
	tr {
		height: 40px;
		line-height: 40px;
		&:last-child {
			td {
				border-bottom: 0px;
			}
		}
		&.hidden {
			display: none;
			td {
				white-space: normal;
				vertical-align: top;
				background: rgba(0, 0, 0, 0.05);
				h4 {
					margin: 0px;
					font-weight: 600;
				}
				span.code {
					font-family: monospace;
					font-size: 12px;
				}
			}
		}
		&.selected {
			td {
				background: rgba(0, 0, 0, 0.05);
			}
		}
		&.break {
			height: 20px;
			background: #E6E6E6;
		}
		&.verification {
			background: @light-grey;
		}
	}
	td {
		padding: 6px 12px;
		&:first-child {
			padding-left: 24px;
		}
		&:last-child {
			padding-right: 24px;
		}
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		border-bottom: 1px solid @light-grey;
		max-width: 196px;
		&.download {
			width: 20px;
			img {
				height: 20px;
				margin: 10px 0;
				display: block;
			}
		}
		&.invalid {
			background: #F47174;
		}
		&.warning {
			color: red;
		}
		a {
			color: #00040C;
			text-decoration: none;
			font-weight: 500;
			cursor: pointer;
			&.copy {
				margin: 12px 0;
			}
			&.copy, &.download {
				display: block;
				padding: 2px;
				border-radius: 8px;
				float: right;
				&:hover {
					background: rgba(0, 0, 0, 0.1);
				}
				img {
					display: block;
					height: 22px;
				}
			}
			&:hover {
				text-decoration: underline;
			}
		}
		&.date {
			color: #85898E;
		}
		&.status {
			span {
				text-transform: capitalize;
				border-radius: 10px;
				font-size: 12px;
				padding: 2px 8px;
				font-weight: 500;
				&.active {
					background: #EBFAF8;
					color: #2A9D90;
				}
				&.pending {
					color: #1E8AAE;
					background: #EFF8FF;
				}
			}
		}
		&.action {
			text-align: right;
		}
		label {
			text-align: center;
			padding: 8px 14px;
			border: 1px solid @light-grey;
			border-radius: 4px;
			font-size: 14px;
			font-weight: 500;
			text-decoration: none;
			color: #232323;
			margin: 36px auto;
			width: 240px;
			cursor: pointer;
			input[type="file"] {
				display: none;
			}
		}
	}
}

a.button {
	min-width: 60px;
	text-align: center;
	padding: 8px 14px;
	border: 1px solid @light-grey;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	color: #232323;
	margin-right: 24px;
	&:last-child {
		margin-right: 0px;
	}
	&:hover {
		text-decoration: none;
	}
	&.loading {
		opacity: 0.5;
		pointer-events: none;
	}
}

.arrow {
	display: inline-block;
	width: 0;
	height: 2px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #858892;
	&.rotate {
		transform: rotate(180deg);
		height: 0px;
	}
}

a.download {
	background: white;
	cursor: pointer;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
	line-height: 40px;
	font-size: 0.9em;
	padding: 0 16px;
	margin-left: 16px;
	img {
		float: left;
		height: 14px;
		margin: 13px 8px 13px 0;
	}
}

span.status {
	font-size: 0.8em;
	background: #6fc276;
	padding: 8px 12px;
	border-radius: 15px;
	color: white;
	&.inactive {
		background: #f47174;
	}
}
span.sandbox {
	margin-left: 10px;
	font-size: 0.8em;
	background: #A8CCEE;
	padding: 8px 12px;
	border-radius: 15px;
	color: white;
}

.metadata {
	overflow: hidden;
	margin-bottom: 24px;
	background: white;
	padding: 24px;
	border-radius: 8px;
	border: 1px solid @light-grey;
	h2 {
		margin: 0 0 10px 0;
		font-size: 1.2em;
	}
	span {
		display: inline-block;
		margin-right: 10px;
		color: #85898E;
	}
}

a.action {
	float: right;
	font-size: 0.8em;
	font-weight: 600;
	color: white;
	text-decoration: none;
	padding: 12px 24px;
	border-radius: 8px;
	margin-bottom: 12px;
	background: #126AEA;
	cursor: pointer;
}

.notes {
	margin-left: 0px;
	.note {
		display: flex;
		margin-bottom: 18px;
		font-size: 14px;
		max-width: 400px;
		div {
			display: block;
			&.avatar {
				background: red;
				margin-right: 12px;
				height: 30px;
				line-height: 30px;
				width: 30px;
				text-align: center;
				border-radius: 50%;
				color: white;
				flex-shrink: 0;
			}
			&.content-container {
				flex-grow: 1;
			}
			.content {
				line-height: 22px;
			}
			.meta {
				margin-bottom: 12px;
				font-size: 12px;
				color: #858892;
				span {
					font-size: 14px;
					font-weight: 500;
					margin-right: 12px;
					color: #232323;
				}
			}
		}
	}
	.form {
		margin-top: 12px;
		a.button {
			cursor: pointer;
		}
		input {
			margin: 0px;
			margin-bottom: 12px;
		}
		.flex {
			display: flex;
			select {
				margin-bottom: 0px;
			}
		}
	}
}

.dashboard {
	padding: 0px 32px 36px 272px;
	background: #F9F7F4;
	min-height: 100vh;
	box-sizing: border-box;
	@media only screen and (max-width: 600px) {
		padding: 0px 32px 36px 80px;
	}
}

.modal {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 99;
	padding: 36px;
	background: white;
	overflow: scroll;
	div {
		max-width: 640px;
		margin: 0 auto;
	}
}

code {
	background: @light-grey;
	padding: 4px;
	border-radius: 4px;
	font-family: "PT Mono";
	font-size: 0.9em;
}

.header, .footer {
	z-index: 99;
	display: flex;
	h1 {
		margin: 36px 0;
		font-size: 30px;
		line-height: 40px;
		font-weight: 500;
	}
	div {
		margin: 36px 0 36px auto;
		display: flex;
		select {
			margin-bottom: 0px;
			width: 180px;
			height: 40px;
		}
		a.button, label.button {
			background: rgba(254, 195, 113, 1);
			border-radius: 4px;
			border: 0px;
			height: 40px;
			line-height: 40px;
			text-decoration: none;
			text-align: center;
			padding: 0 16px;
			color: #232323;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			display: inline-block;
			input[type="file"] {
				opacity: 0;
			}
		}
	}
}

.footer {
	position: fixed;
	left: 240px;
	bottom: 0px;
	right: 0px;
	background: white;
	border-top: 1px solid #E6E6E6;
	padding: 0 32px;
	div {
		margin: 18px 0;
		width: 100%;
		span {
			line-height: 40px;
			margin-right: auto;
		}
	}
}

.top-padded {
	padding-top: 89px;
}

.filters {
	margin-bottom: 20px;
	display: flex;
	&.fixed {
		position: fixed;
		top: 0px;
		left: 240px;
		right: 0px;
		padding: 16px 32px;
		background: white;
		border-bottom: 1px solid #E6E6E6;
		z-index: 99;
		select {
			margin-bottom: 0px;
		}
	}
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 140px;
		background-position-y: 7px;
		padding: 0 16px;
		height: 40px;
		line-height: 40px;
		text-indent: 0px;
		display: inline-block;
		background-color: #fff;
		border-radius: 4px;
		border: 1px solid #E6E6E6;
		margin-right: 12px;
		font-size: 14px;
		font-family: "Helvetica Neue";
		font-weight: 500;
		color: #232323;
		box-sizing: border-box;
		width: 170px;
	}
	span {
		height: 40px;
		line-height: 40px;
		margin-right: 12px;
		&.total {
			margin-left: auto;
			margin-right: 0px;
			font-size: 0.9em;
		}
	}
	.search {
		margin-left: auto;
		width: 200px;
		input {
			width: 100%;
			padding: 0px;
			margin: 0px;
			background-color: #fff;
			border-radius: 4px;
			border: 1px solid #E6E6E6;
			height: 36px;
			height: 40px;
			line-height: 40px;
			text-indent: 16px;
			font-size: 14px;
			font-family: "Helvetica Neue";
			outline: none;
		}
	}
}

.search-form {
	display: flex;
	border: 1px solid @light-grey;
	background: white;
	border-radius: 8px;
	padding: 24px;
	margin-bottom: 24px;
	form {
		width: 100%;
		display: flex;
		input, select, button {
			margin: 0px;
			height: 38px;
			&.ng-invalid {
				box-shadow: none;
			}
		}
		button {
			width: 120px;
			margin-left: 24px;
		}
	}
}

.side-bar {
	position: fixed;
	z-index: 99;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 240px;
	padding: 36px 0 36px 16px;
	box-sizing: border-box;
	background: #232323;
	@media only screen and (max-width: 600px) {
		width: 48px;
		padding-left: 0px;
		ul li {
			&.logo {
				display: none;
			}
			a {
				img {
					float: none;
				}
				span {
					display: none;
				}
			}
		}
		ul.bottom {
			padding-left: 0px !important;
		}
	}
	ul {
		margin: 0px;
		box-sizing: border-box;
		list-style: none;
		padding: 0px;
		&.bottom {
			position: absolute;
			bottom: 0px;
			margin: 0px;
			left: 0px;
			right: 0px;
			background: #232323;
			padding: 36px 0 36px 16px;
		}
		li {
			margin-bottom: 8px;
			border-radius: 8px;
			img {
				height: 20px;
				display: block;
				float: left;
				margin: 2px 12px 2px 0;
				&.active-image {
					display: none;
				}
			}
			&.logo {
				margin-bottom: 32px;
				a {
					padding: 0 12px;
					img {
						height: 22px;
						float: none;
					}
				}
			}
			a {
				cursor: pointer;
				text-decoration: none;
				display: block;
				padding: 10px 12px;
				color: #B3B3B3;
				line-height: 24px;
				&.active-link {
					color: #FEC371;
					background: rgba(254, 195, 113, 0.15);
					border-right: 3px solid #FEC371;
					img.default-image {
						display: none;
					}
					img.active-image {
						display: block;
					}
				}
			}
		}
	}
}

.header {
	z-index: 99;
	display: flex;
	h1 {
		margin: 36px 0;
		font-size: 30px;
		line-height: 40px;
		font-weight: 500;
	}
	.stat {
		flex-grow: 1;
		display: block;
		padding: 12px 16px;
		border-radius: 4px;
		border: 1px solid @light-grey;
		margin: 36px 6px;
		font-size: 16px;
		font-family: "Helvetica Neue";
		color: #232323;
		box-sizing: border-box;
		background: white;
		&:first-child {
			margin-left: 0px;
		}
		&:last-child {
			margin-right: 0px;
		}
		b {
			display: block;
			color: #858892;
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 8px;
		}
		span {
			font-size: 1.6em;
			font-weight: 500;
		}
	}
	form {
		display: flex;
		margin: 36px 0;
		input, button {
			margin-bottom: 0px;
		}
		input {
			width: 240px;
		}
		button {
			height: 38px;
			width: 100px;
		}
	}
}

h2 {
	margin: 36px 0;
	font-size: 24px;
	line-height: 40px;
	font-weight: 500;
}

.filter {
	cursor: pointer;
	&.asc img {
		transform: rotate(180deg);
	}
	span {
		display: block;
		float: left;
	}
	img {
		display: block;
		float: left;
		height: 10px;
		margin: 15px 6px;
	}
}

.line-break {
	margin: 48px 0;
	border-bottom: 1px solid @light-grey;
}

.admin section form label span.info {
	right: 0px;
	p {
		bottom: 6px;
	}
}

span.info {
	position: absolute;
	top: 20px;
	right: 20px;
	p {
		display: none;
		position: absolute;
		bottom: 6px;
		left: -60px;
		background: #333;
		color: #fff;
		padding: 8px 12px;
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
		border-radius: 8px;
		width: 120px;
		text-align: center;
		z-index: 99;
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #333;
			position: absolute;
			left: 65px;
			bottom: -4px;
		}
	}
	&:hover {
		p {
			display: block;
		}
	}
}

.columns {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	div {
		flex: 1;
		margin-right: 18px;
		&:last-child {
			margin-right: 0px;
		}
	}
}

.filter-value {
	div {
		font-size: 0.8em;
		display: inline;
		margin-right: 12px;
		font-weight: 500;
		span {
			margin: 0px;
			background: #E6E6E6;
			display: inline;
			padding: 6px 12px 6px 12px;
			border-radius: 4px 0 0 4px;
			&.filter-value-status {
				border-radius: 4px;
			}
		}
		a {
			margin-left: 1px;
			cursor: pointer;
			background: #E6E6E6;
			display: inline;
			padding: 6px 12px 6px 12px;
			border-radius: 0 4px 4px 0;
		}
	}
}

.loading-indicator {
	position: fixed;
	z-index: 99;
	bottom: 32px;
	right: 32px;
	background: #232323;
	color: white;
	border-radius: 4px;
	padding: 6px 12px;
}

.chart {
	span {
		font-weight: 500;
		font-size: 18px;
		font-family: "Poppins";
		line-height: 26px;
	}
}

a.checkbox {
	display: block;
	border: 2px solid @light-grey;
	box-shadow: ;
	width: 14px;
	height: 14px;
	line-height: 14px;
	text-align: center;
	border-radius: 4px;
	margin: 0px;
	&:hover, &.selected {
		border: 2px solid #666666;
	}
	&.selected {
		-webkit-box-shadow: inset 0px 0px 0px 2px #FFF;
		-moz-box-shadow: inset 0px 0px 0px 2px #FFF;
		box-shadow: inset 0px 0px 0px 2px #FFF;
		background-color: #fec371;
	}
}

section {
	flex-direction: column;
	border: 1px solid @light-grey;
	background: white;
	border-radius: 8px;
	padding: 24px;
	h2 {
		font-size: 1.4em;
		margin: 0 0 24px 0;
	}
	p {
		margin: 24px 0;
	}
	flex-grow: 1;
    flex-basis: 0;
    padding: 24px;
    display: border-box;
    margin: 0 12px;
    &:first-child {
    	margin-left: 0px;
    }
    &:last-child {
    	margin-right: 0px;
    }
}

section form {
    flex: 1;
    display: flex;
    flex-direction: column;
}

section form button {
    margin-top: auto;
}

div.payers {
	display: flex;
	section {
		margin: 0 0 24px 0;
		textarea {
			border-radius: 4px;
			border: 1px solid #E6E6E6;
			width: 100%;
			height: 80px;
			box-sizing: border-box;
			padding: 8px 12px;
			font-family: "Helvetica Neue";
			color: #232323;
			font-size: 16px;
			resize: none;
		}
		table {
			width: 100%;
			tbody {
				tr {
					td {
						border-bottom: 0px;
					}
					&:last-child {
						td {
							border-bottom: 1px solid @light-grey;
						}
					}
				}
				&:last-child {
					tr {
						td {
							border-bottom: 0px;
						}
					}
				}
			}
		}
	}
}

ul.tabs {
	margin: 0 0 36px 0;
	padding: 0px;
	list-style: none;
	li {
		display: inline-block;
		margin-right: 18px;
		opacity: 0.5;
		a {
			padding: 8px 0;
			display: block;
			cursor: pointer;
			font-weight: 500;
			text-decoration: none;
			color: @black;
		}
		&.active {
			opacity: 1;
			border-bottom: 2px solid #fec371;
		}
	}
}

div.row {
	display: flex;
	margin-bottom: 24px;
	&.admin table {
		width: auto;
		height: 100px;

		tbody tr td .description {
			max-width: 400px;
			white-space: pre;
			overflow: scroll;
		}
	}
	&.plans div table tbody {
		display: block;
		max-height: 400px;
		overflow: scroll;
		tr {
			display: table-row;
		}
	}
}

div.column {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
}

div.login {
	div.column {
		position: relative;
		&:first-child {
			padding: 28vh 0;
			background: #232323;
		}
		img.top-right {
			position: absolute;
			top: 0px;
			right: 0px;
		}
		img.bottom-left {
			position: absolute;
			bottom: 0px;
			left: 0px;
		}
		div {
			width: 500px;
			img.logo {
				height: 20px;
			}
			h1, h2, h3 {
				margin: 20px 0;
			}
			h2 {
				font-size: 60px;
				line-height: 72px;
				font-weight: 700;
				color: #fff;
			}
			h3 {
				font-size: 16px;
				line-height: 25px;
				font-weight: 400;
				color: #fff;
			}
		}
	}
}

div.eligibility {
	form {
		display: flex;
		.column {
			flex-grow: 1;
			flex-basis: 0;
			display: block;
			margin-right: 12px;
		}
	}
}

.provider-mappings {
	.new-provider-mapping {
		display: flex;
		select, div {
			flex-grow: 1;
			flex-basis: 0;
			margin-bottom: 0px;
		}
	}
	select {
		display: inline-block;
		width: 100px;
	}
	ul {
		list-style: none;
		padding: 0px;
		margin: 24px 0;
		li {
			display: flex;
			margin: 0 0 12px 0;
			&:last-child {
				margin: 0px;
			}
			div {
				flex-grow: 1;
				flex-basis: 0;
				line-height: 38px;
			}
		}
	}
}

div.logs-filters {
	margin-bottom: 20px;
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 100%;
		background-position-y: 7px;
		padding: 12px 26px 12px 12px;
		text-indent: 0px;
		display: inline-block;
		padding: 12px 8px;
		background-color: #EEEDF0;
		border-radius: 8px;
		border: 0px;
		margin-right: 16px;
		font-size: 0.8em;
		text-indent: 10px;
		font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
		color: #00040C;
		outline-color: #5A9EFF;
		margin-bottom: 24px;
		width: 100%;
		box-sizing: border-box;
		width: 180px;
	}
}

#image-file {
	width: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	opacity: 0;
}

pre {
	overflow: scroll;
}

.admin-form {
	.flex {
		display: flex;
		margin-bottom: 12px;
		&.header {
			select, a {
				width: 300px;
				margin: 15px 0 15px auto;
			}
		}
		div {
			flex-grow: 1;
			flex-basis: 0;
			margin-right: 24px;
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	input[type="checkbox"] {
		height: auto;
	}
	button {
		float: none;
		margin-top: 0px;
	}
}

.error {
    color: rgba(255, 0, 0, 1);
}

.validation-message {
    font-size: 12px;
}

.circle {
	display: block;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
    &.circle-initials {
		color: @light-grey;
	}
}

.admin-dropdown {
	position: fixed;
	background: rgba(0, 0, 0, 0.25);
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	ul {
		width: 200px;
		margin: 32px auto;
		background: white;
		list-style: none;
		padding: 0;
		border: 1px solid @light-grey;
		border-radius: 8px;
		overflow: hidden;
		li {
			display: flex;
			align-items: center;
			padding: 12px;
			cursor: pointer;
			font-size: 0.9em;
			&:hover {
				background-color: #E6E6E6;
			}
			.admin-initials {
				margin-right: 6px;
			}
		}
	}
}

.alert {
	background-color: @light-blue;
	color: white;
	padding: 10px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	button {
		background: none;
		border: none;
		color: white;
		cursor: pointer;
		font-size: 16px;
		&:focus {
			outline: none;
		}
	}
}
.alert-success {
	background-color: green;
}

.alert-error {
	background-color: red;
}

.link-button-left {
	.button-default();
	width: auto;
	background-color: @light-grey;
	float: left;
}

.link-button-right {
	.button-default();
	width: auto;
	background-color: @light-grey;
	float: right;
}

.delete-button {
	width: 80px;
	height: 32px;
	background: red;
	color: white;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	text-align: center;
	cursor: pointer;

	&:hover {
		background: darken(red, 10%);
	}
}

.margin-bottom-small {
    margin-bottom: 5px;
}

.margin-bottom-large {
    margin-bottom: 24px;
}

.verification {
	margin-right: 480px;
	@media only screen and (max-width: 600px) {
		margin-right: 0px;
	}
}

.details {
	position: fixed;
	right: 0px;
	width: 480px;
	top: 0px;
	bottom: 0px;
	background: white;
	z-index: 99;
	border-left: 1px solid #E6E6E6;
	overflow: scroll;
	@media only screen and (max-width: 600px) {
		position: initial;
		width: auto;
		border: 1px solid #E6E6E6;
		border-radius: 8px;
	}
}

section .section {
	padding: 24px 0px;
	&:first-child {
		padding-top: 0px;
	}
}

.authenticator {
	max-width: 400px;
	margin: 36px auto;
	p, input {
		text-align: center;
	}
	img {
		margin: auto;
		display: block;
	}
	form {
		text-align: center;
		input {
			width: 200px;
			display: inline-block;
			margin: 0 10px;
			&.ng-invalid {
				box-shadow: none;
			}
		}
	}
}

.filters-button {
	position: relative;
	a {
		display: block;
		padding: 0 14px;
		height: 40px;
		line-height: 40px;
		background: white;
		cursor: pointer;
		margin-right: 12px;
	}
	.dropdowns-container {
		display: none;
		position: absolute;
		top: 40px;
		padding-top: 10px;
	}
	&.open {
		.dropdowns-container {
			display: block;
		}
	}
}

.dropdowns {
	margin: 0px;
	width: 280px;
	height: 400px;
	overflow: scroll;
	h3 {
		margin-top: 0px;
		margin-bottom: 12px;
		font-size: 1em;
	}
	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		margin-bottom: 12px;
		li {
			height: 30px;
			line-height: 30px;
			cursor: pointer;
			font-size: 0.9em;
			overflow: hidden;
			input[type="checkbox"] {
				height: initial;
				margin-right: 6px;
				pointer-events: none;
			}
		}
	}
	div:last-child {
		ul {
			margin-bottom: 0px;
		}
	}
}

.section {
	h4 {
		margin-top: 0px;
	}
	h5 {
		margin-bottom: 12px;
	}
	p {
		font-size: 0.9em;
		min-height: 25px;
		&:last-child {
			margin-bottom: 0px;
		}
		&.empty {
			color: #858892;
		}
	}
	&:last-child {
		border-bottom: 0px;
	}
	padding: 24px;
	border-bottom: 1px solid #E6E6E6;
}

.json-view {
	pre {
		padding: 2px;
		margin: 2px;
		font-family: inherit;
		font-size: inherit;
		overflow: hidden;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		display: inline-block;
		vertical-align: top;
		width: 75%;
		max-width: calc(95% - 75px);
		background: #E6E6E6;
		border-radius: 8px;
		line-height: 1.4;
	}
	pre.expanded {
		white-space: pre-wrap;
		display: block;
		width: 100%;
		max-width: 100%;
	}
}
