/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
  @apply h-full min-h-screen;
  @apply font-sans;
}

.menu li a {
  @apply text-gray-400 font-semibold;
  svg {
    @apply text-gray-400;
  }
  &.active-link {
    @apply bg-accent text-white font-semibold;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
  &:hover {
    @apply text-white;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
}

.filters-button {
  position: relative;
  .dropdowns-container {
    display: none;
    position: absolute;
    top: 40px;
    padding-top: 10px;
  }
  &.open {
    .dropdowns-container {
      display: block;
    }
  }
}

.badge {
  border-radius: 6px;
}

#file-upload {
  display: none;
}

.side-bar {
  background-color: #14191f;
}

.hero {
  background-color: #14191F;
}

.dropdown-content {
  z-index: 99;
  margin-top: 8px;
  li {
    border-bottom-width: 1px;
    @apply p-2 border-gray-200;
    a {
      padding: 8px;
      @apply text-black font-normal;
      &:hover {
        @apply text-black bg-gray-100;
      }
    }
    &:last-child {
      border-width: 0px;
    }
  }
}

/* Legacy */

@black: #00040C;
@light-grey: #E6E6E6;
@dark-blue: #126AEA;
@light-blue: #5A9EFF;
@dark-yellow: #FEC371;

.button-default() {
  float: right;
  background: @dark-yellow;
  border-radius: 4px;
  border: 0px;
  height: 46px;
  color: #232323;
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  margin: 20px 0;
}

span.info {
  position: absolute;
  top: 20px;
  right: 20px;
  p {
    display: none;
    position: absolute;
    bottom: 6px;
    left: -60px;
    background: #333;
    color: #fff;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 8px;
    width: 120px;
    text-align: center;
    z-index: 99;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #333;
      position: absolute;
      left: 65px;
      bottom: -4px;
    }
  }
  &:hover {
    p {
      display: block;
    }
  }
}

.multiple-select {
  list-style: none;
  height: 150px;
  overflow: scroll;
}

.inline {
  display: flex;
  a.button {
    margin-bottom: 24px;
  }
}

span.status {
  font-size: 0.8em;
  background: #6fc276;
  padding: 8px 12px;
  border-radius: 15px;
  color: white;
  &.inactive {
    background: #f47174;
  }
}
span.sandbox {
  margin-left: 10px;
  font-size: 0.8em;
  background: #A8CCEE;
  padding: 8px 12px;
  border-radius: 15px;
  color: white;
}

section.events {
  div.event {
    margin-bottom: 30px;
    position: relative;
    &:last-child {
      margin-bottom: 0px;
    }
    div.dot {
      position: absolute;
      div {
        background: #E6E6E6;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        margin: 5px 0;
      }
    }
    div.line {
      width: 0px;
      position: absolute;
      left: 4px;
      top: 15px;
      bottom: -35px;
      border: 1px solid #E6E6E6;
    }
    &:last-child {
      div.line {
        display: none;
      }
    }
    div.title {
      display: flex;
      margin-left: 20px;
      line-height: 20px;
      font-size: 14px;
      a {
        text-decoration: none;
        font-weight: bold;
        color: #00040C;
      }
      div.date {
        margin-left: auto;
        font-size: 12px;
        color: #777;
      }
    }
    div.metadata {
      margin-top: 20px;
      height: 18px;
      margin-left: 20px;
      padding: 0px;
      border: 0px;
      display: flex;
      font-size: 14px;
      overflow: visible;
      margin-bottom: 0px;
      div {
        margin-right: 10px;
        line-height: 18px;
        &:last-child {
          margin: 0px;
        }
      }
      div.action {
        margin-left: auto;
        .button {
          padding: 4px 14px;
        }
      }
    }
    pre {
      background: #E6E6E6;
      border-radius: 8px;
      margin-left: 20px;
      padding: 16px;
      margin-top: 20px;
      max-height: 400px;
      margin-bottom: 0px;
    }
  }
}

.metadata {
  overflow: hidden;
  margin-bottom: 24px;
  background: white;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid @light-grey;
  h2 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
  }
  span {
    display: inline-block;
    margin-right: 10px;
    color: #85898E;
  }
}

a.action {
  float: right;
  font-size: 0.8em;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #126AEA;
  cursor: pointer;
}

.notes {
  margin-left: 0px;
  .note {
    display: flex;
    margin-bottom: 18px;
    font-size: 14px;
    max-width: 400px;
    div {
      display: block;
      &.avatar {
        background: red;
        margin-right: 12px;
        height: 30px;
        line-height: 30px;
        width: 30px;
        text-align: center;
        border-radius: 50%;
        color: white;
        flex-shrink: 0;
      }
      &.content-container {
        flex-grow: 1;
      }
      .content {
        line-height: 22px;
      }
      .meta {
        margin-bottom: 12px;
        font-size: 12px;
        color: #858892;
        span {
          font-size: 14px;
          font-weight: 500;
          margin-right: 12px;
          color: #232323;
        }
      }
    }
  }
  .form {
    margin-top: 12px;
    a.button {
      cursor: pointer;
    }
    input {
      margin: 0px;
      margin-bottom: 12px;
    }
    .flex {
      display: flex;
      select {
        margin-bottom: 0px;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  padding: 36px;
  background: white;
  overflow: scroll;
  div {
    max-width: 640px;
    margin: 0 auto;
  }
}

code {
  background: @light-grey;
  padding: 4px;
  border-radius: 4px;
  font-family: "PT Mono";
  font-size: 0.9em;
}

.filter-values-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.filter-value {
  div {
    font-size: 0.8em;
    display: inline;
    margin-right: 12px;
    font-weight: 500;
    span {
      margin: 0px;
      background: #E6E6E6;
      display: inline;
      padding: 6px 12px 6px 12px;
      border-radius: 4px 0 0 4px;
      &.filter-value-status {
        border-radius: 4px;
      }
    }
    a {
      margin-left: 1px;
      cursor: pointer;
      background: #E6E6E6;
      display: inline;
      padding: 6px 12px 6px 12px;
      border-radius: 0 4px 4px 0;
    }
  }
}

a.checkbox {
  display: block;
  border: 2px solid @light-grey;
  box-shadow: ;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
  margin: 0px;
  &:hover, &.selected {
    border: 2px solid #666666;
  }
  &.selected {
    -webkit-box-shadow: inset 0px 0px 0px 2px #FFF;
    -moz-box-shadow: inset 0px 0px 0px 2px #FFF;
    box-shadow: inset 0px 0px 0px 2px #FFF;
    background-color: #fec371;
  }
}

ul.tabs {
  margin: 0 0 36px 0;
  padding: 0px;
  list-style: none;
  li {
    display: inline-block;
    margin-right: 18px;
    opacity: 0.5;
    a {
      padding: 8px 0;
      display: block;
      cursor: pointer;
      font-weight: 500;
      text-decoration: none;
      color: @black;
    }
    &.active {
      opacity: 1;
      border-bottom: 2px solid #fec371;
    }
  }
}

div.login {
  div.column {
    position: relative;
    &:first-child {
      padding: 28vh 0;
      background: #232323;
    }
    img.top-right {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    img.bottom-left {
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
    div {
      width: 500px;
      img.logo {
        height: 20px;
      }
      h1, h2, h3 {
        margin: 20px 0;
      }
      h2 {
        font-size: 60px;
        line-height: 72px;
        font-weight: 700;
        color: #fff;
      }
      h3 {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}

pre {
  overflow: scroll;
}

.error {
    color: rgba(255, 0, 0, 1);
}

.validation-message {
    font-size: 12px;
}

.circle {
  display: block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
    &.circle-initials {
    color: @light-grey;
  }
}

.alert {
  background-color: @light-blue;
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
}
.alert-success {
  background-color: green;
}

.alert-error {
  background-color: red;
}

.link-button-left {
  .button-default();
  width: auto;
  background-color: @light-grey;
  float: left;
}

.link-button-right {
  .button-default();
  width: auto;
  background-color: @light-grey;
  float: right;
}

.verification {
  margin-right: 480px;
  @media only screen and (max-width: 600px) {
    margin-right: 0px;
  }
}

.details {
  position: fixed;
  right: 0px;
  width: 480px;
  top: 0px;
  bottom: 0px;
  background: white;
  z-index: 99;
  border-left: 1px solid #E6E6E6;
  overflow: scroll;
  @media only screen and (max-width: 600px) {
    position: initial;
    width: auto;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
  }
}

.authenticator {
  max-width: 400px;
  margin: 36px auto;
  p, input {
    text-align: center;
  }
  img {
    margin: auto;
    display: block;
  }
  form {
    text-align: center;
    input {
      width: 200px;
      display: inline-block;
      margin: 0 10px;
      &.ng-invalid {
        box-shadow: none;
      }
    }
  }
}

.json-view {
  pre {
    padding: 2px;
    margin: 2px;
    font-family: inherit;
    font-size: inherit;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 75%;
    max-width: calc(95% - 75px);
    background: #E6E6E6;
    border-radius: 8px;
    line-height: 1.4;
  }
  pre.expanded {
    white-space: pre-wrap;
    display: block;
    width: 100%;
    max-width: 100%;
  }
}